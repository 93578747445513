.dropdown-content a {
    color: black;
    padding: 16px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3a3939;
}

.checkbox {
    display: block;
    position: relative;
    margin-left: 10px;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.check {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.checkbox:hover input ~ .check {
    border: 2px solid #0066ff;
}

.checkbox input:checked ~ .check {
    background-color: #0066ff;
    border: none;
}

.check:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .check:after {
    display: block;
}

.checkbox .check:after {
    left: 6px;
    top: 3px;
    width: 8px;
    height: 11px;
    border: 2.55599px solid #fff;
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox input:focus + .check {
    border: 2px solid #0066ff;
}

.checkbox input:checked:focus + .check {
    border: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.checkbox:hover input:checked + .check {
    border: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.radio {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #000000;
    border-radius: 50%;
}
.radio .radio-groomer-check{
    height: 20px;
    width: 20px;
}
.radio input:disabled ~ .check {
    background-color: #eee;
}

.radio:hover input ~ .check {
    border: 2px solid #0d0d0e;
}

.radio input:checked ~ .check  {
    background-color: rgb(255, 255, 255);
    border: 1px solid #0066ff;
}

.radio .check:after {
    content: "";
    position: absolute;
    display: none;
}
.radio input:checked ~ .check:after {
    display: block;
}

.radio .check:after {
    top: 3px;
    left: 3px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #0066ff;
}
.radio .radio-groomer-check:after {
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #0066ff;
}
.radio input:focus + .check {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.radio input:focus + .radio-groomer-check:after {
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}
.appointment-suggestion-header {
    .suggestion-date-secondary {
      font-family: "Plain Medium";
      font-weight: 600 !important;
      color: #191919;
      font-size: 17px;
    }

    .suggestion-time-secondary {
      font-family: "Plain";
      color: #2b273d;
      font-weight: 400 !important;
      font-size: 17px;
    }
    .suggestion-context-secondary {
      font-family: "Plain";
      color: #2b273d;
      font-weight: 400 !important;
      font-size: 17px;
    }
    .suggestion-stylist-secondary {
      color: #737373;
      font-size: 15px;
    }

    .suggestion-date {
      font-family: "Plain Medium";
      font-weight: 700 !important;
    }

    .suggestion-time {
      font-family: "Plain Medium";
      font-weight: 700 !important;
    }

    .suggestion-context {
      color: #737373;
    }

    .suggestion-stylist {
      color: #737373;
    }
  }

  .slot-suggestion-container {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    justify-content: space-between;

    @media screen and (max-width: 530px) {
      width: 100%;
    }

  .slot-suggestion-date-text {
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    width: 100%;
  }

  .slot-suggestion-time-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 15px;
    font-weight: 600;
  }

  .slot-suggestion-details-text {
    font-size: 15px;
    font-weight: 400;
    color: #737373;
  }

  .slot-suggestion-distance-text {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    background-color: #0066ffb0;
    border-radius: 32px;
    padding: 0px 6px;
  }
}